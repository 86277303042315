<!--电能分析--节能管理---->
<template>
    <div id="energyAnalysisData" ref="" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 页面标题 -->
        <div class="header-title">
            <span>{{ $t("energy.enedata062") }}</span>
        </div>

        <div class="header">
            <div class="header-left">
                <div class="header-condition">
                    <span>{{ $t("energy.enedata060") }}</span>
                    <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
                        <a-radio-button  v-for="(itme, index) in this.eneKindList"
                            :key="index"
                            :value="itme.no"> {{ itme.text }}</a-radio-button>
                    </a-radio-group>
                </div>
            
                <div class="header-condition" v-if="rpt < '4'">
                    <span>{{ $t("energy.enedata061") }}</span>
                    <a-select v-model="leveling" style="width: 120px" :disabled="defaultStatistic==1" @change="levelingTypeChange">
                        <a-select-option
                            v-for="(itme, index) in this.levelingTypeList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="header-condition" v-if="rpt < '4'">
                    <span>{{ $t("energy.enedata020") }}</span>
                    <a-select v-model="basicUnit" style="width: 120px" @change="basicUnitChange">
                        <a-select-option
                            v-for="(itme, index) in this.basicUnitList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="header-condition" v-if="rpt=='1'">
                    <span>{{ $t("energy.enedata002") }}</span>
                    <a-select v-model="dayType" style="width: 120px" @change="dayTypeChange" :disabled="typeDisable">
                        <a-select-option
                            v-for="(itme, index) in this.dayTypeList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="header-condition" v-if="rpt=='4'">
                    <span>{{ $t("energy.enedata021") }}</span>
                    <a-select v-model="co2Type" style="width: 120px" @change="co2TypeChange">
                        <a-select-option
                            v-for="(itme, index) in this.co2TypeList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
        </div>

        <div class="header">
            <div class="header-left"> 
                <div class="header-condition">
                    <span>{{ $t("energy.enedata005") }}</span>
                    <a-select v-model="year" style="width: 120px" @change="yearChange">
                        <a-select-option
                            v-for="(itme, index) in this.yearList"
                            :key="index"
                            :value="itme"
                            >
                            {{ itme }}
                        </a-select-option>
                    </a-select>
                </div>

               <!-- 显示对象 -->
               <div class="header-condition">
                    <span>{{ $t("energy.enedata004") }}</span>
                    <a-radio-group v-model="chartKind" button-style="solid" @change="drawCharts">
                        <a-radio-button value="line">{{ $t("energy.enedata013") }}</a-radio-button>
                        <a-radio-button value="bar">{{ $t("energy.enedata014") }}</a-radio-button>
                        <a-radio-button value="pie" :disabled="rpt=='5'">{{ $t("energy.enedata015") }}</a-radio-button>
                    </a-radio-group>
                </div>

                <div class="header-condition">
                    <span>{{$t("energy.enedata398")}}</span>
                    <a-switch v-model="msel" @change="mselChange" :disabled="(leveling=='1'&&(rpt!='4'||rpt!='5'))" :style="{'width':'60px','margin-top':'5px'}" />
                </div>
                <div class="header-condition">
                    <span>{{$t("energy.enedata399")}}</span>
                    <a-select v-model="month" style="width: 120px" :disabled="defaultStatistic==0" >
                    <a-select-option
                        v-for="(itme, index) in this.monthList"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                        </a-select-option>
                    </a-select>
                </div>

            </div>
    
            <div>
                <a-button  type="primary" style="width: 120px"  @click="search()"> {{ $t("energy.enedata006") }} </a-button>
            </div>
        </div>

        <div class="main">
            <div id="energyAnalysisDataChart" :style="{width: '100%', height: '450px'}" ></div>   
            <div>
                <div style="text-align:right;" v-if="data!=null && data.length>0">
                    <a-button  type="primary" style="width: 120px"  @click="csvout()"> {{ $t("energy.enedata042") }} </a-button>
                </div>
                <a-table :style="{width: '100%', height: '300px',padding:'10px'}"
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :scroll="xscroll"
                    size="small"
                >
                    <template v-for="col in showItems"
                        :slot="col"
                        slot-scope="text,record,index" >
                        <div :key="col" >
                            <span v-if="(index<2 || index>3) && ( text == '-' || parseInt(text)>=0)">{{ text }}</span>
                            <span style="color:green;" v-else-if="(index>1 && index<4) && ( text == '-' || parseInt(text)>=0)">{{ text }}</span>
                            <span style="color:red;" v-else>{{ text }}</span>
                        </div>
                    </template>
                </a-table>
                                
            </div>     
        </div> 
   
    </div>
</template>



<script>
import { getEnergyAnalysisData,downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment";

export default {
  name: "energyAnalysisData",
  data() {
    
    return {
      loading: false,
      eneKindList:[],
      rpt:'',
      levelingTypeList:[],
      leveling:'',
      basicUnitList:[],
      basicUnit:'',
      dayTypeList:[],
      dayType:'',
      co2TypeList:[],
      co2Type:'',
      yearList:[],
      year:0,
      
      chartData:null,
      chartKind:'bar',
      reportName:'',
      levelingName:'',

      data:null,
      columns:null,
      pagination:false,
      xscroll: { x: 700 },

      typeDisable:false,
      showItems:['item2','item3','item4','item5','item6','item7','item8','item9','item10','item11','item12','item13'],
      areaStyle1:null,
      areaStyle2:null,
      msel:false,
      defaultStatistic:0,
      month:"1",
      monthList:[],
      monthMap:null,
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.columns = [];
        this.data = [];
        this.initPage();
        this.drawDefultChart();
    }
  },
  mounted() { 
    // this.initPage();
    // this.drawDefultChart();
    //实现自适应部分
    window.addEventListener("resize", () => this.resizeChart());
    this.areaStyle1 = {
                opacity: 0.8,
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(255, 0, 135)'
                }, {
                    offset: 1,
                    color: 'rgba(135, 0, 157)'
                }])
            };
    this.areaStyle2 = {
                opacity: 0.8,
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(55, 162, 255)'
                }, {
                    offset: 1,
                    color: 'rgba(116, 21, 219)'
                }])
            };
  },
  beforeDestroy() {
   
  },
  methods:{
    mselChange(){
      console.log("mselChange",this.msel);
      if(this.msel){
        this.defaultStatistic = 1;
      }else{
        this.defaultStatistic = 0;
      }
    },
    drawCharts(){
        if(this.rpt ==='5'){
            if(this.chartFareData){
                this.drawFareCharts();
            }
        }else{
            if(this.chartData){
                let titleName = this.$t("energy.enedata062") +"-"+ this.reportName;
                let subtext = this.chartData.target.unit;
                let option = null;
                let title  = {
                    text: titleName,
                    //subtext: subtext,
                    ///left:"center"
                };
                if(this.leveling=='0'){
                    option = this.getChartData(this.chartKind);
                }else{
                    option = this.getLevelingChartData(this.chartKind);
                }
               
                if(this.chartKind=='pie'){
                    title = option.title;
                }
                option && this.drawChart(title,subtext,option.legends,option.categorys,option.series,option.yAxis,option.vm);
            }
        }
    },
    drawChart(title,subtext,legends,categorys,series,yAxis,vm){
      // 基于准备好的dom，初始化echarts实例
      let energyAnalysisDataChart = this.$echarts.init(document.getElementById("energyAnalysisDataChart"));
      // 绘制图表
      let option = null;
      if(this.chartKind=='pie'){
          option = {
              title:title,
              toolbox: {
                  show: true,
                  feature: {
                      mark: {show: true},
                      dataView: {show: true, readOnly: false},
                      restore: {show: true},
                      saveAsImage: {show: true}
                  }
              },
              tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} '+subtext+'({d}%)'
              },
              legend:legends,
              series: series
          };
      }else{
        option = {
            title: title,
            toolbox: {
                show: true,
                feature: {
                    mark: {show: true},
                    dataView: {show: true, readOnly: false},
                    restore: {show: true},
                    saveAsImage: {show: true}
                }
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: legends
            },
            xAxis: {
                type: 'category',
                data: categorys
            },
            yAxis:yAxis,
            visualMap:vm,
            series: series
        };
      }
      
      if(option){
        console.log(option)
        // 使用刚指定的配置项和数据显示图表。
        energyAnalysisDataChart.setOption(option,true);
        this.resizeChart();
      }
     
    },
    getLevelingPieChartData(){
        let charType = 'pie';
        let legends ={
            orient: 'vertical',
            left: 'left',
        };
        const data = this.chartData;
        let categorys = new Array();
        let series = new Array();
        let data1 = new Array();
        let data2 = new Array();

        let data3 = new Array();
        let data4 = new Array();
      
        categorys = data.actual.categories;

        let actualLeveling = new Array();
        let targetLeveling = new Array();

        for (let i = 0; i < data.target.data.length; i++) {
            let a = new Number(data.actual.data[i]+data.levelingBfApr.data[i]);
            let t = new Number(data.target.data[i]+data.leveling.data[i]);
            actualLeveling.push(a.toFixed(3));
            targetLeveling.push(t.toFixed(3));
            
        }

        for(let i=0;i<categorys.length;i++){
            let label = null;
            if(data.actual.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.actual.unit+'({d}%)'};
               label = {formatter: '{b} : {d}%'};
            }
            let d =  {value: data.actual.data[i], name: categorys[i],label:label};
            data1.push(d);

            label = null;
            if(data.target.data[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.target.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d2 =  {value: data.target.data[i], name: categorys[i],label:label};
            data2.push(d2);

            label = null;
            if(actualLeveling[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.actual.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d3 =  {value: actualLeveling[i], name: categorys[i],label:label};
            data3.push(d3);

            label = null;
            if(targetLeveling[i]*1>0){
            //   label = {formatter: '{b} \n {c} '+data.target.unit+'({d}%)'};
                 label = {formatter: '{b} : {d}%'};
            }
            let d4 =  {value: targetLeveling[i], name: categorys[i],label:label};
            data4.push(d4);
        }
        let radius = [10, 60];
        let sery1 = {
            name: data.actual.name,
            type: 'pie',
            radius: radius,
            center: ['30%', '30%'],
            selectedMode: 'single',
            data: data1,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery2 = {
            name: data.target.name,
            type: 'pie',
            radius: radius,
            center: ['75%', '30%'],
            selectedMode: 'single',
            data: data2,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        
        let sery3 = {
            name: this.$t('energy.enedata068'),
            type: 'pie',
            radius:radius,
            center: ['30%', '75%'],
            selectedMode: 'single',
            data: data3,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery4 = {
            name: this.$t('energy.enedata067'),
            type: 'pie',
            radius: radius,
            center: ['75%', '75%'],
            selectedMode: 'single',
            data: data4,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
                
        series.push(sery1);
        series.push(sery2);
        series.push(sery3);
        series.push(sery4);

        let title = [{
                    text:  this.$t("energy.enedata062")+"-"+this.reportName,
                    left: 'center',
                }, {
                    subtext: data.actual.name,
                    left: '30%',
                    top: '45%',
                    textAlign: 'center'
                }, {
                    subtext: data.target.name,
                    left: '75%',
                    top: '45%',
                    textAlign: 'center'
                }, {
                    subtext: this.$t('energy.enedata068'),
                    left: '30%',
                    bottom: '1%',
                    textAlign: 'center'
                }, {
                    subtext: this.$t('energy.enedata067'),
                    left: '75%',
                    bottom: '1%',
                    textAlign: 'center'
                }];

    
        let option = {
            title:title,
            legends:legends,
            series:series,
            categorys:null,
            visualMap:null,
            yAxis:null
        };

        return option;
    },
    getPieChartData(){
        let charType = 'pie';
        let legends ={
            orient: 'vertical',
            left: 'left',
        };
        const data = this.chartData;
        let categorys = new Array();
        let series = new Array();
        let data1 = new Array();
        let data2 = new Array();
      
        categorys = data.actual.categories;

        for(let i=0;i<categorys.length;i++){
            let label = null;
            if(data.actual.data[i]*1>0){
              label = {formatter: '{b} \n {c} '+data.actual.unit+'({d}%)'};
            }
            let d =  {value: data.actual.data[i], name: categorys[i],label:label};
            data1.push(d);

            label = null;
            if(data.target.data[i]*1>0){
              label = {formatter: '{b} \n {c} '+data.target.unit+'({d}%)'};
            }
            let d2 =  {value: data.target.data[i], name: categorys[i],label:label};
            data2.push(d2);
        }

        let sery1 = {
            name: data.actual.name,
            type: 'pie',
            radius: [20, 100],
            center: ['30%', '50%'],
            selectedMode: 'single',
            data: data1,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }

        let sery2 = {
            name: data.target.name,
            type: 'pie',
            radius: [20, 100],
            center: ['75%', '50%'],
            selectedMode: 'single',
            data: data2,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
                
        series.push(sery1);
        series.push(sery2);

        let title = [{
                    text:  this.$t("energy.enedata062")+"-"+this.reportName,
                    left: 'center',
                }, {
                    subtext: data.actual.name,
                    left: '25%',
                    bottom: '5%',
                    textAlign: 'center'
                }, {
                    subtext: data.target.name,
                    left: '75%',
                    bottom: '5%',
                    textAlign: 'center'
                }];

    
        let option = {
            title:title,
            legends:legends,
            series:series,
            categorys:null,
            visualMap:null,
            yAxis:null
        };

        return option;
    },
    getLevelingLineChartData(){
        let legends = new Array();
        let categorys = new Array();
        let series = new Array();
        const data = this.chartData;
        categorys = data.actual.categories;
        let yAxiArr = new Array();
        let yAxi1 =  {
            name: data.actual.unit,
            type: 'value'
        };
        yAxiArr.push(yAxi1);

        let sery1 = {
            name: data.actual.name ,
            data: data.actual.data,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };

        let sery2 = {
            name: data.target.name,
            data: data.target.data,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };

        let actualLeveling = new Array();
        let targetLeveling = new Array();

        for (let i = 0; i < data.target.data.length; i++) {
            let a = new Number(data.actual.data[i]+data.levelingBfApr.data[i]);
            let t = new Number(data.target.data[i]+data.leveling.data[i]);
            actualLeveling.push(a.toFixed(3));
            targetLeveling.push(t.toFixed(3));
            
        }
        
        let sery3 = {
            name: this.$t('energy.enedata068') ,
            data: actualLeveling,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };

        let sery4 = {
            name: this.$t('energy.enedata067') ,
            data: targetLeveling,
            type: 'line',
            smooth:true,
            emphasis: {
                focus: 'series'
            }
        };


        series.push(sery2);
        series.push(sery1);
        series.push(sery4);
        series.push(sery3);

        legends.push(data.target.name);
        legends.push(data.actual.name);
        legends.push(sery3.name);
        legends.push(sery4.name);
     
        let option = {
            legends:legends,
            series:series,
            categorys:categorys,
            visualMap:[],
            yAxis:yAxiArr
        }

        return option;

    },
    getLevelingChartData(charType){
        if(this.chartKind==='line'){
            return this.getLevelingLineChartData();
        }else if(this.chartKind==='pie'){
            return this.getLevelingPieChartData();
        }
        let legends = new Array();
        let categorys = new Array();
        let series = new Array();
        const data = this.chartData;
        categorys = data.actual.categories;


        let yAxiArr = new Array();
        let yAxi1 =  {
            name: data.actual.unit,
            type: 'value'
        };
        yAxiArr.push(yAxi1);

        let sery1 = {
            name: data.actual.name ,
            data: data.actual.data,
            type: charType,
            stack: 'ACTUAL',
            emphasis: {
                focus: 'series'
            }
        };

        let sery2 = {
            name: data.target.name,
            data: data.target.data,
            type: charType,
            stack: 'TARGET',
            emphasis: {
                focus: 'series'
            }
        };


        let sery3 = {
            name: data.levelingBfApr.name ,
            data: data.levelingBfApr.data,
            type: charType,
            stack: 'ACTUAL',
            emphasis: {
                focus: 'series'
            }
        };

        let sery4 = {
            name: data.leveling.name,
            data: data.leveling.data,
            type: charType,
            stack: 'TARGET',
            emphasis: {
                focus: 'series'
            }
        };

        if('line' === this.chartKind){
            sery4.smooth=true;
            sery3.smooth=true;
            sery2.smooth=true;
            sery1.smooth=true;

            sery1.areaStyle= this.areaStyle1;
            sery3.areaStyle= this.areaStyle1;
            sery2.areaStyle= this.areaStyle2;
            sery4.areaStyle= this.areaStyle2;

            series.push(sery2);
            series.push(sery1);
            legends.push(data.target.name);
            legends.push(data.actual.name);

        }else{
            series.push(sery2);
            series.push(sery1);
            series.push(sery4);
            series.push(sery3);

            legends.push(data.target.name);
            legends.push(data.actual.name);
            legends.push(data.leveling.name);
            legends.push(data.levelingBfApr.name);
        }
                    
       

        
        let option = {
            legends:legends,
            series:series,
            categorys:categorys,
            visualMap:[],
            yAxis:yAxiArr
        }

        return option;
    },
    getChartData(charType){
        if(charType=='pie'){
            return  this.getPieChartData();
        }
        let legends = new Array();
        let categorys = new Array();
        let series = new Array();

        const data = this.chartData;
      

        let sTag = -1;
        let vm =  null;
        let yAxiArr = new Array();
        let yAxi1 =  {
                name: data.actual.unit,
                type: 'value'
            };
        yAxiArr.push(yAxi1);

        
        categorys = data.actual.categories;
        
        let sery1 = {
            name: data.actual.name ,
            data: data.actual.data,
            type: charType,
            smooth: true
        };

        let sery2 = {
            name: data.target.name,
            data: data.target.data,
            type: charType,
            smooth: true
        };
                    
        series.push(sery2);
        series.push(sery1);
        legends.push(data.target.name);
        legends.push(data.actual.name);

        let option = {
            legends:legends,
            series:series,
            categorys:categorys,
            visualMap:vm,
            yAxis:yAxiArr
        }

        return option;
    },
    csvout(){
         let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            year:this.year,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.rpt=='1' || this.rpt=='2' || this.rpt=='3'){
            params.basicUnit = this.basicUnit;
            params.levelingType = this.leveling;
            if(this.rpt=='1'){
                params.timeType = this.dayType;
            }
        }else if(this.rpt=='4'){
            params.co2Type = this.co2Type;
        }
        console.log("downloadEnergyCSVDataWithURL Params",params);
        this.loading = true;

        downloadEnergyCSVDataWithURL('bivale/energy/data/analysis/saving/download',params)
        .then((res) => {
            let name  = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.reportName+'-';
            // if(this.rpt!='4'){
            //   name +=this.pointTypeName+'-'
            // }
            name += moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    rptChange(e){
      console.log('rptChange',e);
      let t = parseInt(e.target.value);
      this.reportName = this.eneKindList[t-1].text;
      this.chartData = null;
      this.chartFareData = null;
      this.columns = [];
      this.data = [];
      this.drawDefultChart();
      this.co2Type = this.co2TypeList[0].no;
      switch(t){
          case 1:
          break;
          case 2:
          break;
          case 3:
          break;
          case 4:
              this.leveling = this.levelingTypeList[0].no;
              this.basicUnit = this.basicUnitList[0].no;
          break;
          case 5:
              this.leveling = this.levelingTypeList[0].no;
              this.basicUnit = this.basicUnitList[0].no;
          break;
      }
    },
    search(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            year:this.year,            
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.rpt=='1' || this.rpt=='2' || this.rpt=='3'){
            params.basicUnit = this.basicUnit;
            params.levelingType = this.leveling;
            if(this.rpt=='1'){
                params.timeType = this.dayType;
            }
        }else if(this.rpt=='4'){
            params.co2Type = this.co2Type;
        }
        console.log("GetEnergyAnalysisData Params",params);
        this.loading = true;

        getEnergyAnalysisData(params)
        .then((res) => {
            
            console.log("GetEnergyAnalysisData Result",res);
            if(res.errorCode == '00'){
                if(res.data.err == -1){
                    this.$message.error(this.$t('energy.enedata065'));
                }else if(res.data.err == -2){
                    this.$message.error(this.$t('energy.enedata066'));
                }else{
                    if(this.rpt === '5'){
                        this.columns = res.data.columnList;
                        this.data    = res.data.bodyList;
                        this.chartFareData = {
                            categories:res.data.electricity.categories,
                            electricity:res.data.electricity,
                            gas:res.data.gas,
                            oil:res.data.oil,
                            water:res.data.water,
                            other:res.data.other,
                        }
                        this.drawFareCharts();
                    }else{
                        this.columns = res.data.columnList;
                        this.data    = res.data.bodyList;
                        if( this.leveling =='0'){
                            this.chartData = {
                                target:res.data.target,
                                actual:res.data.actual,
                                leveling: this.leveling,
                            }
                        }else{
                            this.chartData = {
                                target:res.data.levelingTarget,
                                actual:res.data.levelingActual,
                                leveling: res.data.leveling,
                                levelingBfApr:res.data.levelingBeforeApr,
                            }
                        }
                        this.drawCharts();
                    }
                    
                }
                
                
                
            }
           
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    yearChange(e){
       console.log('yearChange',e);
       this.search();
    },
    dayTypeChange(e){
       console.log('dayTypeChange',e);
       this.search();
    },
    co2TypeChange(e){
       console.log('co2TypeChange',e);
       this.search();
    },
    basicUnitChange(e){
        console.log('basicUnitChange',e);
        this.search();
    },
    levelingTypeChange(e){
      console.log('levelingTypeChange',e);
      if(e=='1'){
          this.dayType = '0';
          this.typeDisable = true;
      }else{
          this.typeDisable = false;
      }
      this.search();
    },
    initPage(){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getEnergyAnalysisData(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                	this.eneKindList			 =	res.data.eneKindList;
                    this.rpt					 =	res.data.eneKindList[0].no;
                    this.reportName              =  res.data.eneKindList[0].text;
                    this.levelingTypeList		 =	res.data.levelingTypeList;
                    this.leveling                =	res.data.levelingTypeList[0].no;
                    this.basicUnitList			 =	res.data.basicUnitList;
                    this.basicUnit               =	res.data.basicUnitList[0].no;
                    this.dayTypeList			 =	res.data.dayTypeList;
                    this.dayType                 =	res.data.dayTypeList[0].no;
                    this.co2TypeList			 =	res.data.co2TypeList;
                    this.co2Type                 =	res.data.co2TypeList[0].no;
                    this.yearList				 =	res.data.yearList;
                    this.year                    =	res.data.fiscalYear;
                    this.monthList               =	res.data.monthList;
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    drawDefultChart(){
      let option =  this.getDefaultOption();
      // 基于准备好的dom，初始化echarts实例
      let energyAnalysisDataChart = this.$echarts.init(document.getElementById("energyAnalysisDataChart"));
      option && energyAnalysisDataChart.setOption(option, true);
      this.resizeChart();
    },
    drawFareCharts(){
        if(this.chartKind=='pie'){

        }else{
            let category =  this.chartFareData.categories; 
            let electricity ={
                name: this.chartFareData.electricity.name,
                type: this.chartKind,
                barGap: 0,
            // label: labelOption,
                emphasis: {
                    focus: 'series'
                },
                data:this.chartFareData.electricity.data,
            };
            let gas = {
                name: this.chartFareData.gas.name,
                type: this.chartKind,
                barGap: 0,
            // label: labelOption,
                emphasis: {
                    focus: 'series'
                },
                data:this.chartFareData.gas.data,
            };
            let oil = {
                name: this.chartFareData.oil.name,
                type: this.chartKind,
                barGap: 0,
            // label: labelOption,
                emphasis: {
                    focus: 'series'
                },
                data:this.chartFareData.oil.data,
            };
            let water = {
                name: this.chartFareData.water.name,
                type: this.chartKind,
                barGap: 0,
            // label: labelOption,
                emphasis: {
                    focus: 'series'
                },
                data:this.chartFareData.water.data,
            };
        let other = {
                name: this.chartFareData.other.name,
                type: this.chartKind,
                barGap: 0,
            // label: labelOption,
                emphasis: {
                    focus: 'series'
                },
                data:this.chartFareData.other.data,
            };
            let series = new Array();
            series.push(electricity);
            series.push(water);
            series.push(gas);
            series.push(oil);
            series.push(other);

            let titleName = this.$t("energy.enedata062") +"-"+ this.reportName;
            let subtext = this.chartFareData.water.unit;
                
            let yAxiArr = new Array();
            let yAxi1 =  {
                    name: subtext,
                    type: 'value'
                };
            yAxiArr.push(yAxi1);
            let option = {
                title: {
                    text: titleName,
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: {show: true},
                        dataView: {show: true, readOnly: false},
                        restore: {show: true},
                        saveAsImage: {show: true}
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: category,
                },
                yAxis: yAxiArr,
                legend:{ },
                series:series,
            };

            let energyAnalysisDataChart = this.$echarts.init(document.getElementById("energyAnalysisDataChart"));
            option && energyAnalysisDataChart.setOption(option, true);
            this.resizeChart();

        }
        
    },
    getDefaultOption(){
        let category =  ['04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03']; 
        
        let option = {
            xAxis: {
                type: 'category',
                data: category,
            },
            yAxis: {
                type: 'value'
            },
            legend:{ },
            series: [{
                data: [0],
                type: 'line',
                smooth: true
            }]
        };
        return option;
    },
     resizeChart(){
      if(document.getElementById("energyAnalysisDataChart")){ 
         this.$echarts.init(document.getElementById("energyAnalysisDataChart")).resize();
      }
    },
    
  },
  destroyed(){
    window.removeEventListener("resize", () => this.resizeChart());
  },
  components: {
    
  },
};
</script>

<style scoped>

#energyAnalysisData {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width:  4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.main{
    width: 100%;
    height:calc(100% - 160px);
    overflow: auto;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>


